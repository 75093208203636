








import { Component, Prop, Vue } from 'vue-property-decorator';
import config from '~/config/preview';

@Component({})
export default class EditButton extends Vue {
    @Prop() readonly id!: string;

    get active(): boolean {
        return process.env.APP_ENV === 'preview';
    }

    get url(): string {
        return `https://app.contentful.com/spaces/${config.contentful.spaceId}/entries/${this.id}`;
    }
}
